.outer_page_content {
  overflow: hidden;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.outer_page_content .reading_top_container {
  height: 45%;
  background-color: white;
  overflow: scroll;
}
.outer_page_content .reaidng_middle_container {
  height: 1px;
  border: solid 1px #ddd;
}
.outer_page_content .reading_middle_container {
  flex: 2;
  background-color: white;
  position: relative;
  overflow-y: scroll;
}
