




















































































































































.outer_page_content {
  overflow: hidden;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .reading_top_container {
    height: 45%;
    background-color: white;
    overflow: scroll;
  }
  .reaidng_middle_container {
    height: 1px;
    border: solid 1px #ddd;
  }
  .reading_middle_container {
    flex: 2;
    background-color: white;
    position: relative;
    overflow-y: scroll;
  }
}
